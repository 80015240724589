.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.pin-field {
  border: 1px solid gray;
  border-right: none;
  font-size: 2rem;
  height: 3rem;
  width: 16.5%;
  outline: none;
  text-align: center;
  transition-duration: 250ms;
  transition-property: color, border, box-shadow, transform;
  margin-right: 0.5rem;
  border-radius: 0.5rem;
}

.pin-field:focus {
  border-color: blue;
  box-shadow: 0 0 0.25rem rgba(blue, 0.5);
  outline: none;
}
.pin-field:focus + .pin-field {
  border-left-color: blue;
}

.pin-field:invalid {
  animation: shake 5 linear 75ms;
  border-color: red;
  box-shadow: 0 0 0.25rem rgba(red, 0.5);
}

.pin-field:invalid + .pin-field {
  border-left-color: red;
}

.pin-field.complete {
  border-color: green;
  color: green;
}
.pin-field.complete[disabled] {
  background: rgba(green, 0.1);
  cursor: not-allowed;
  opacity: 0.5;
}

.pin-field.complete[disabled] + .pin-field {
  border-left-color: green;
}

@keyframes shake {
  from {
    transform: translateY(-5%);
  }
  to {
    transform: translateY(5%);
  }
}

.selectedTab::before {
  content: '';
  width: 1rem;
  height: 1rem;
  position: absolute;
  border-top-left-radius: 20px;
  top: 0;
  left: 0rem;
  background: transparent;
  box-shadow: -5px -5px 0px 4px black;
}

.selectedTab::after {
    content: '';
    width: 1rem;
    height: 1rem;
    position: absolute;
    border-top-right-radius: 20px;
    top: 0;
    right: 0rem;
    background: transparent;
    box-shadow: 5px -5px 0px 4px black;
}



