@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Raleway&family=Roboto:wght@400;500;700&display=swap');

@layer components {
  .card {
    background-color: theme('colors.black-80');
    border-radius: theme('borderRadius.lg');
    box-shadow: theme('boxShadow.xl');
    backdrop-filter: blur(8px);
  }

  .shadow {
    text-shadow: 0 4px 20px rgb(0 0 0 / 90%);
  }
}

body {
  background-color: black;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  background-color: transparent;
  border: none;
}

.Toastify__toast-body {
  color: white;
}

.toast-success {
  background-color: green !important;
}

.toast-error {
  background-color: red !important;
}

.form-check-input:checked[type='radio'] {
  @apply border-main bg-main text-main;
}

.form-control:focus {
  @apply border-gray shadow-none;
}

.form-check-input[type='checkbox'] {
  @apply rounded-full;
}

.form-check-input:checked {
  @apply border-main bg-main text-main;
}

.form-check-input:focus {
  @apply shadow-none;
}

.p-speeddial-action {
  @apply bg-neon-purple !important;
}

.p-speeddial-opened > ul li a {
  height: 3rem !important;
}

.p-speeddial > ul li a {
  height: 0;
}

.p-speeddial-item {
  margin: 0 0.25rem !important;
}

.p-speeddial-opened .p-speeddial-item {
  margin: 0.25rem !important;
}

.p-speeddial-opened .p-speeddial-item:first-child {
  margin-bottom: 0.5rem !important;
}

.gradient-background {
  @apply bg-gradient-to-bl from-[#547FAA] to-[#57122E];
}

.sticky-menu-hide {
  opacity: initial;
  transition: 1s all ease;
  transform: translateY(0px);
}

.sticky-menu-hide.active {
  opacity: 0;
  transform: translateY(150px);
}

.sticky-header-hide {
  opacity: initial;
  transition: 1s all ease;
  transform: translateY(0px);
}

.sticky-header-hide.active {
  opacity: 0;
  transform: translateY(-100px);
}

.faded-blur {
  transition: 1s all ease;
  -webkit-mask-image: linear-gradient(to bottom, black 10%, transparent 80%);
  mask-image: linear-gradient(to bottom, black 10%, transparent 80%);
  --tw-backdrop-blur: blur(12px);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast)
    var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert)
    var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  opacity: 1;
}

#playlists::-webkit-scrollbar,
.scroll-section::-webkit-scrollbar {
  display: none;
}

.modal-content {
  @apply overflow-hidden rounded-xl bg-black;
}

.custom-animation {
  animation: customPulse 3s linear infinite;
}

@keyframes customPulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.fade-in {
  animation: customOpacity 0.1s linear;
}

@keyframes customOpacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.webkit-line-clamp-3 {
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  @apply overflow-hidden;
}

.webkit-line-clamp-2 {
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  @apply overflow-hidden;
}

.Toastify__toast-container--top-center {
  width: fit-content !important;
}

.ray_box {
  position: absolute;
  margin: auto;
  top: 6rem;
  left: 0;
  right: 0;
  bottom: 0;
  width: 70px;
}
.ray {
  background: linear-gradient(
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  margin-left: 10px;
  border-radius: 80% 80% 0 0;
  position: absolute;
  opacity: 0.1;
}

.ray1 {
  height: 170px;
  width: 30px;
  -webkit-transform: rotate(180deg);
  top: -175px;
  left: 15px;
}
.ray2 {
  height: 100px;
  width: 8px;
  -webkit-transform: rotate(220deg);
  top: -90px;
  left: 75px;
}
.ray3 {
  height: 170px;
  width: 50px;
  -webkit-transform: rotate(250deg);
  top: -80px;
  left: 100px;
}
.ray4 {
  height: 120px;
  width: 14px;
  -webkit-transform: rotate(305deg);
  top: 30px;
  left: 100px;
}
.ray5 {
  height: 140px;
  width: 30px;
  -webkit-transform: rotate(-15deg);
  top: 60px;
  left: 40px;
}
.ray6 {
  height: 90px;
  width: 50px;
  -webkit-transform: rotate(30deg);
  top: 60px;
  left: -40px;
}
.ray7 {
  height: 180px;
  width: 10px;
  -webkit-transform: rotate(70deg);
  top: -35px;
  left: -40px;
}
.ray8 {
  height: 120px;
  width: 30px;
  -webkit-transform: rotate(100deg);
  top: -45px;
  left: -90px;
}
.ray9 {
  height: 80px;
  width: 10px;
  -webkit-transform: rotate(120deg);
  top: -65px;
  left: -60px;
}
.ray10 {
  height: 190px;
  width: 23px;
  -webkit-transform: rotate(150deg);
  top: -185px;
  left: -60px;
}

.song-item:last-of-type,
.participant-item:last-of-type,
.winner-item:last-of-type,
.request-item:last-of-type {
  @apply !pb-24;
}
